import moment from "moment-timezone";
import _ from "lodash";
import GetCurrency from "./services";
import { CurrencyWithAmount } from "./utils";
import React from 'react';


export const refreshPage = () => {
	window.location.reload(false);
};

/**
 *
 * @param array
 */
function shuffle(array) {
	var currentIndex = array.length,
		randomIndex;
	while (0 !== currentIndex) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
}

export const hostedConfList = array => {
	const duplicationArray = shuffle(array);
	const copy_duplicateArray = Array.from(duplicationArray);
	const temp = [];
	temp.push(...copy_duplicateArray.splice(0, 1));
	temp.push(...copy_duplicateArray.splice(5, 1));
	return duplicationArray.concat(temp);
};

export const featuredEventSpace = length => {
	if (length > 3) {
		return (Math.floor(length / 4) + 1) * 275;
	} else return 250;
};

export const capitalizeFirstLetter = string => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 *
 * @param text
 */
export function capitalizeWords(text) {
	// Split the text into words
	var words = text.split(" ");

	// Capitalize the first letter of each word
	var capitalizedWords = words.map(function (word) {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});

	// Join the words back together
	var capitalizedText = capitalizedWords.join(" ");

	return capitalizedText;
}

export const titleCase = str => {
	var splitStr = str.toLowerCase().split(" ");
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(" ");
};

// get youtube image thumbnail
export const get_youtube_thumbnail = (
	url,
	backgroundUrl = "https://image-dock-uploads-be.s3.ap-south-1.amazonaws.com/image.2021-04-19T04%3A54%3A26.365Z"
) => {
	if (url) {
		var video_id, thumbnail, result;
		if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
			video_id = result.pop();
		} else if ((result = url.match(/youtu.be\/(.{11})/))) {
			video_id = result.pop();
		}

		if (video_id) {
			var thumbnail = `https://img.youtube.com/vi/${video_id}/hqdefault.jpg`;
			return thumbnail;
		}
	}
	return backgroundUrl;
};

//convert html to plain text and take only 165 characters
export const convertToPlainSeo = html => {
	return `${html.replace(/<[^>]+>/g, "").substring(0, 160)}...`;
};

// Returns date and time from utc to IST
export const getISTDateNTime = dateTime => {
	let tempData;
	tempData = moment.utc(dateTime).toDate();
	tempData = moment(tempData).format("YYYY-MM-DD HH:mm:ss");

	return tempData;
};

// Returns converted date & time based on timezone
export const convertTimezone = (dateTime, timezone, optional) => {
	let convertedDateTime;
	convertedDateTime = moment.utc(dateTime).tz(timezone);

	if (optional == "ticket") {
		return moment(convertedDateTime).format("YYYY-MM-DD HH:mm").replace(/ /g, "T");
	} else return convertedDateTime;
};

// Returns date and time from utc to IST
export const getDateFromTimeStamp = dateTime => {
	let tempData;
	tempData = moment(dateTime).format("YYYY-MM-DD");
	return tempData;
};
export const CURRENCY_ID_MAPPING = {
    1: "INR",
    2: "USD",
    3: "AMD",
    4: "ARS",
    5: "AUD",
    6: "AWG",
    7: "BBD",
    8: "BDT",
    9: "BMD",
    10: "BND",
    11: "BOB",
    12: "BSD",
    13: "BWP",
    14: "BZD",
    15: "CAD",
    16: "CHF",
    17: "CNY",
    18: "COP",
    19: "CRC",
    20: "CUP",
    21: "CZK",
    22: "DKK",
    23: "DOP",
    24: "DZD",
    25: "EGP",
    26: "ETB",
    27: "EUR",
    28: "FJD",
    29: "GBP",
    30: "GIP",
    31: "GHS",
    32: "GMD",
    33: "GTQ",
    34: "GYD",
    35: "HKD",
    36: "HNL",
    37: "HRK",
    38: "HTG",
    39: "HUF",
    40: "IDR",
    41: "ILS",
    42: "AED",
    43: "JMD",
    44: "KES",
    45: "KGS",
    46: "KHR",
    47: "KYD",
    48: "KZT",
    49: "LAK",
    50: "LBP",
    51: "LKR",
    52: "LRD",
    53: "LSL",
    54: "MAD",
    55: "MDL",
    56: "MKD",
    57: "MMK",
    58: "MNT",
    59: "MOP",
    60: "MUR",
    61: "MVR",
    62: "MWK",
    63: "MXN",
    64: "MYR",
    65: "NAD",
    66: "NGN",
    67: "NIO",
    68: "NOK",
    69: "NPR",
    70: "NZD",
    71: "PEN",
    72: "PGK",
    73: "PHP",
    74: "PKR",
    75: "QAR",
    76: "RUB",
    77: "SAR",
    78: "SCR",
    79: "SEK",
    80: "SGD",
    81: "SLL",
    82: "SOS",
    83: "SSP",
    84: "SVC",
    85: "SZL",
    86: "THB",
    87: "TTD",
    88: "TZS",
    89: "ALL",
    90: "UYU",
    91: "UZS",
    92: "YER",
    93: "ZAR",
    94: "JPY",
    95: "KWD",
    96: "BRL",
    97: "BGN",
    98: "PLN",
    99: "RON",
    100: "BHD",
    101: "OMR",
}
export const userBookingStatuses = {
	0: { status: "", color: "" },
	1: { status: "Lead", color: "#0CAF30" },
	2: { status: "Active", color: "#0CAF30" },
	3: { status: "Cancelled", color: "#FB5850" },
	4: { status: "", color: "" },
	5: { status: "", color: "" },
	6: { status: "Waitlist", color: "#0CAF30" },
	7: { status: "Waitlist_Approved", color: "#0CAF30" },
	8: { status: "Waitlist_Rejected", color: "#FB5850" }
};
export const REGISTRATION_STATUS = {
	LEAD: 1,
	ACTIVE: 2,
	CANCEL: 3,
	WAITLIST: 6,
	APPROVAL_PENDING: 10,
};

export const WAITLIST_STATUSES = {
	WAITLIST: 1,
	WAITLIST_APPROVED: 2,
	WAITLIST_REJECTED: 3,
	WAITLIST_DELETED: 4,
	APPROVAL_PENDING: 5,
	APPROVAL_APPROVED: 6,
	APPROVAL_REJECTED: 7,
	APPROVAL_DELETED: 8,
	APPROVAL_ON_HOLD: 9,
};


export const statusColors = {
	Lead: "#6C757D",
	Confirmed: "#0CAF30",
	Cancelled: "#DC3545",
	Waitlisted: "#737373",
	"Approval Pending": "#737373",
	"Approved": "#E9BC1C",
	"Waitlist Rejected": "#DC3545",
	"Waitlist Deleted": "#6C757D",
	"Approval Rejected": "#DC3545",
	"Approval Deleted": "#6C757D",
	"Approval On Hold": "#525252",
	"Yet to be approved": "#DC3545",
};

export const registrationStatusMap = {
	[REGISTRATION_STATUS.LEAD]: "Lead",
	[REGISTRATION_STATUS.ACTIVE]: "Confirmed",
	[REGISTRATION_STATUS.CANCEL]: "Cancelled",
	[REGISTRATION_STATUS.WAITLIST]: "Waitlisted",
	[REGISTRATION_STATUS.APPROVAL_PENDING]: "Approval Pending",
};

export const waitlistStatusMap = {
	[WAITLIST_STATUSES.WAITLIST]: "Waitlisted",
	[WAITLIST_STATUSES.WAITLIST_APPROVED]: "Approved",
	[WAITLIST_STATUSES.WAITLIST_REJECTED]: "Waitlist Rejected",
	[WAITLIST_STATUSES.WAITLIST_DELETED]: "Waitlist Deleted",
	[WAITLIST_STATUSES.APPROVAL_PENDING]: "Approval Pending",
	[WAITLIST_STATUSES.APPROVAL_APPROVED]: "Approved",
	[WAITLIST_STATUSES.APPROVAL_REJECTED]: "Approval Rejected",
	[WAITLIST_STATUSES.APPROVAL_DELETED]: "Approval Deleted",
	[WAITLIST_STATUSES.APPROVAL_ON_HOLD]: "Approval On Hold",
};

export const determineTicketStatus = (each, registrationStatusMap, waitlistStatusMap) => {
	let status = "";
  
	// If registration_status is ACTIVE (2), mark as "Confirmed" and skip waitlist checks
	if (each?.registration_status === REGISTRATION_STATUS.ACTIVE) {
	  status = registrationStatusMap[REGISTRATION_STATUS.ACTIVE];
	  return status; 
	}
  
	// If registration_status is CANCELLED (3), mark as "Cancelled" and skip waitlist checks
	if (each?.registration_status === REGISTRATION_STATUS.CANCEL) {
	  status = registrationStatusMap[REGISTRATION_STATUS.CANCEL];
	  return status; 
	}
  
	// Otherwise, map the registration status
	if (each?.registration_status in registrationStatusMap) {
	  status = registrationStatusMap[each?.registration_status];
	}
  
	// Check waitlist status if available and registration_status is not ACTIVE or CANCELLED
	if (each?.waitlist_status && each?.waitlist_status in waitlistStatusMap) {
	  status = waitlistStatusMap[each?.waitlist_status];
	}
  
	return status;
  };
  
  
  
  export  const formatDateAndTime = (timestamp) => {
	const localTimezone = moment.tz.guess();
	return {
	  date: moment.utc(timestamp).local().format("MMM Do"),
	  year: moment.utc(timestamp).local().format("YYYY"),
	  time: moment.utc(timestamp).local().format("h:mm A"),
	  timezone: moment.tz(timestamp, localTimezone).format("z"),
	};
  };
  
export const isSameDate = (startTimestamp, endTimestamp) => {
	const start = formatDateAndTime(startTimestamp);
	const end = formatDateAndTime(endTimestamp);
  
	if (start.date === end.date && start.year === end.year && start.timezone === end.timezone) {
	  // If the start and end are on the same day in the same year
	  return `${start.date} & ${start.year}, ${start.time} (${start.timezone})`;
	} else if (start.year === end.year) {
	  // If the year is the same but the dates are different
	  return `${start.date}, ${start.year}, ${start.time} ${start.timezone} - ${end.date}, ${end.time} ${end.timezone}`;
	} else {
	  // If the year is different
	  return `${start.date}, ${start.year}, ${start.time} ${start.timezone} - ${end.date}, ${end.year}, ${end.time} ${end.timezone}`;
	}
  };
  
export const formatEventDates = (startTimestamp, endTimestamp) => {
  const start = formatDateAndTime(startTimestamp);
  const end = formatDateAndTime(endTimestamp);

  const combinedDates =
	start.date === end.date
	  ? start.date // If the same date, show it once
	  : `${start.date} & ${end.date}`; // If different, show both dates

  return `${combinedDates}, ${end.year}, ${start.time} (${start.timezone})`;
};

// To figure if it is a light or a dark color
export const fontColorFunction = (hex, setColor) => {
	const colorCode = hex.replace("#", "");
	const r = Number.parseInt(colorCode.substr(0, 2), 16);
	const g = Number.parseInt(colorCode.substr(2, 2), 16);
	const b = Number.parseInt(colorCode.substr(4, 2), 16);
	const a = Number.parseInt(colorCode.substr(6, 2) || "FF", 16);

	const brightness = (r * 299 + g * 587 + b * 114) / 1000;
	return setColor != "opposite"
		? brightness > 125
			? `rgba(51, 51, 51, ${a / 255})`
			: `rgba(255, 255, 255, ${a / 255})`
		: brightness < 125
			? `rgba(51, 51, 51, ${a / 255})`
			: `rgba(255, 255, 255, ${a / 255})`;
};

// event url regex validation
export const eventUrlValidation = value => {
	return value.match(/^[a-z0-9-]+$/);
};

// filters two object, and return the objects that have the same idd
/**
 *
 * @param array1
 * @param array2
 */
export function filterObjectsById(array1, array2) {
	// Convert array2 into a Set for faster lookup
	const idSet = new Set(array2.map(obj => obj.ticket_id));

	// Filter objects from array1 whose id is present in array2
	const filteredArray = array1.filter(obj => idSet.has(obj.ticket_id));

	return filteredArray;
}

/**
 *
 * @param string
 * @param search
 * @param replace
 */
export function replaceAll(string, search, replace) {
	// replaceAll alternative as replaceAll doesnt work while deploying
	return string.split(search).join(replace);
}

//Group ticket discount time
/**
 *
 * @param val
 */
export function isOnDiscount(val) {
	const todaysTime = moment().valueOf();
	let discountStartTime;
	let discountEndTime;
	val &&
		val.some(({ start_timestamp, end_timestamp }) => {
			discountStartTime = moment.utc(start_timestamp).local().valueOf();
			discountEndTime = moment.utc(end_timestamp).local().valueOf();
		});
	return todaysTime >= discountStartTime && todaysTime <= discountEndTime;
}
/**
 *
 * @param groupDiscounts
 * @param ticketId
 */
export function hasGroupDiscountOffer(groupDiscounts, ticketId) {
	if (_.isEmpty(groupDiscounts)) return false;

	const all_ticket_ids_with_group_discount = [];
	for (const groupDiscountObj of groupDiscounts) {
		all_ticket_ids_with_group_discount.push(...groupDiscountObj.attached_ticket_ids);
	}

	if (all_ticket_ids_with_group_discount.includes(ticketId)) return true;

	return false;
}

/**
 *
 * @param discounts
 * @param tickets
 * @param couponCode
 */
export function getGroupDiscountDetails(discounts, tickets, couponCode) {
	// Initialize variables to keep track of the maximum discount and its code
	if (_.isEmpty(discounts) || _.isEmpty(tickets) || couponCode !== "")
		return { maxDiscount: 0, maxDiscountCode: "" };

	// Initialize variables to keep track of the maximum discount and its code
	let maxDiscount = 0;
	let maxDiscountCode = "";

	// Iterate through each discount in the discounts array
	for (const discount of discounts) {
		// Check if the discount is a group discount and is enabled
		if (discount.is_group_discount && discount.is_enabled) {
			// Initialize variables to keep track of the total number of selected tickets
			// and the applicable total ticket price for this discount
			let totalSelectedTickets = 0;
			let applicableTicketPrice = 0;

			// Iterate through each ticket in the tickets array
			for (const ticket of tickets) {
				// Check if the current ticket's ID is included in the discount's attached_ticket_ids
				if (discount.attached_ticket_ids.includes(ticket.ticket_id)) {
					// Add the number of selected tickets to the total
					totalSelectedTickets += ticket.totalSelected;
					// Add the total price for the selected tickets to the applicable ticket price
					applicableTicketPrice += ticket.ticket_price * ticket.totalSelected;
				}
			}

			// Check if the total number of selected tickets within the discount's attached_ticket_ids is within the min and max limits
			if (
				totalSelectedTickets >= discount.minimum_tickets &&
				totalSelectedTickets <= discount.maximum_tickets
			) {
				let discountAmount = 0;

				// Calculate the discount amount based on the discount type
				if (discount.coupon_type === 2) {
					// For percentage-based discounts (coupon_type 2)
					discountAmount = (applicableTicketPrice * discount.discount) / 100;
				} else if (discount.coupon_type === 1) {
					// For fixed amount discounts (coupon_type 1), scale the discount with the number of selected tickets
					// Ensure the discount does not exceed the total price
					// TODO: check this logic
					discountAmount = Math.min(
						discount.discount * totalSelectedTickets,
						applicableTicketPrice
					);
				}

				// Update maxDiscount and maxDiscountCode if the current discount amount is greater
				if (discountAmount > maxDiscount) {
					maxDiscount = discountAmount;
					maxDiscountCode = discount.coupon_code;
				}
			}
		}
	}

	// Return an object containing the maximum discount amount and the corresponding discount code
	return { maxDiscount, maxDiscountCode };
}

//Group ticket discount conditions text
/**
 *
 * @param val
 * @param data
 */
export function discountContentByCondition(val, data = false) {
	if ((data && val.attached_ticket_ids.includes(data.ticket_id)) || data === false) {
		if (val.minimum_tickets == 0 && val.coupon_type == 1) {
			return (
				<>
					You avail minimum of{" "}
					<CurrencyWithAmount
						fontSize={"14px"}
						currencyName={val?.currency_name}
						number={val?.discount}
					/>{" "}
					discount for purchasing upto {val.maximum_tickets} tickets.
				</>
			);
		} else if (val.minimum_tickets == 0 && val.coupon_type == 2) {
			return (
				<>
					You avail minimum of {val.discount}% discount for purchasing upto{" "}
					{val.maximum_tickets} tickets.
				</>
			);
		} else if (val.minimum_tickets != 0 && val.coupon_type == 1) {
			return (
				<>
					You avail minimum of{" "}
					<CurrencyWithAmount
						fontSize={"14px"}
						currencyName={val?.currency_name}
						number={val?.discount}
					/>{" "}
					discount for purchasing between {val.minimum_tickets} and {val.maximum_tickets}{" "}
					tickets.
				</>
			);
		} else if (val.minimum_tickets != 0 && val.coupon_type == 2) {
			return (
				<>
					You avail minimum of {val.discount}% discount for purchasing between{" "}
					{val.minimum_tickets} and {val.maximum_tickets} tickets.
				</>
			);
		} else if (val.coupon_type == 1) {
			return (
				<>
					You can avail minimum of{" "}
					<CurrencyWithAmount
						fontSize={"14px"}
						currencyName={val?.currency_name}
						number={val?.discount}
					/>{" "}
					discount for purchasing {val.minimum_tickets} tickets at least.
				</>
			);
		} else if (val.coupon_type == 2) {
			return (
				<>
					You can avail minimum of {val.discount}% discount for purchasing{" "}
					{val.minimum_tickets} tickets at least.
				</>
			);
		}
	}
}

export const categorizeTickets = tickets => {
	const uncategorized = [];
	let categorized = {};
	tickets.forEach(ticket => {
		if (!ticket.category) uncategorized.push(ticket);
		if (ticket.category) {
			if (!categorized[ticket.category.category_id])
				categorized[ticket.category.category_id] = {
					category_name: ticket.category.category_name,
					category_description: ticket.category.category_description,
					category_id: ticket.category.category_id,
					category_order: ticket.category.category_order,
					is_expanded: ticket.category.is_expanded,
					tickets: []
				};
			categorized[ticket.category.category_id].tickets.push(ticket);
		}
	});
	categorized = Object.values(categorized);
	categorized.sort((a, b) => a.category_order - b.category_order);
	return { uncategorized, categorized };
};

/**
 *
 * @param email
 */
export function checkPotentialDomainTypo(email) {
	// Extract the domain after "@"
	const domain = email?.split("@")[1]?.toLowerCase();

	// Define a list of common typos (modify this list as needed)
	const commonTypos = [
		"gmal.com",
		"gnail.com",
		"gamal.com",
		"gmal.com",
		"gamil.com",
		"gaml.com",
		"gmail.in",
		"gmail.co",
		"gmail.in",
		"gemail.com",
		"hotmal.com",
		"yaho.com"
	];
	// Check if the domain exists in the list
	if (domain) {
		return commonTypos.includes(domain) ? true : false;
	}

	// No match found or invalid email format
	return true;
}

export const checkTeamBasedRegStatus = tickets => {
	let teamRegstatus = 0; // 0 -> No team based tickets, 1 -> Only team based tickets, 2 -> team based and individual tickets
	const teamBasedTickets = tickets.filter(ticket => ticket.team_registration === true);
	if (!_.isEmpty(teamBasedTickets)) {
		if (teamBasedTickets.length === tickets.length) teamRegstatus = 1;
		else teamRegstatus = 2;
	} else {
		teamRegstatus = 0;
	}

	return teamRegstatus;
};

export const regexMethods = {
	//This is used for certaing regex operation
	allowOnlyPositiveNumber: evt => {
		const charCode = String.fromCharCode(evt.which);
		if (!/[0-9]/.test(charCode)) {
			evt.preventDefault();
		}
		return;
	},
	allowAlphaNumeric: evt => {
		const charCode = String.fromCharCode(evt.which);
		if (!/^[a-z0-9-]+/i.test(charCode)) {
			evt.preventDefault();
		}
		return;
	},
	allowAlphaNumericAndSpecial: evt => {
		//alphanumeric and accpet special characters :- @, &, #, $, !, (, ), *
		const charCode = String.fromCharCode(evt.which);
		if (!/^[a-zA-Z0-9@&#$!()*-]+$/.test(charCode)) {
			evt.preventDefault();
		}
		return;
	},
	noSpaces: evt => {
		const charCode = String.fromCharCode(evt.which);
		if (!/^\S*$/.test(charCode)) {
			evt.preventDefault();
		}
		return;
	}
};

export const scrollToError = (errors) => {
	let isScrollingTriggered = false; // Flag to prevent multiple scrolls

	if (isScrollingTriggered) return; // Prevent scrolling if already triggered

	for (const parentKey in errors) {
		if (errors.hasOwnProperty(parentKey)) {
			const nestedErrors = errors[parentKey];
			for (const fieldKey in nestedErrors) {
				if (nestedErrors.hasOwnProperty(fieldKey)) {
					// Generate the correct name attribute for the nested input field
					const firstErrorFieldName = `${parentKey}.${fieldKey}`;

					// Locate the element with the error
					const firstErrorElement = document.querySelector(`[name="${firstErrorFieldName}"]`);
					if (firstErrorElement) {
						// Adjust for fixed headers (modify this value as needed)
						const headerOffset = 900; // Set your actual header height
						const elementPosition = firstErrorElement.getBoundingClientRect().top + window.scrollY;
						const offsetPosition = elementPosition - headerOffset;

						// Scroll smoothly
						window.scrollTo({
							top: offsetPosition,
							behavior: 'smooth',
						});

						// Focus on the element, prevent default scroll behavior when focusing
						setTimeout(() => {
							firstErrorElement.focus({ preventScroll: true });
						}, 300);

						// Set the flag to prevent further scrolling
						isScrollingTriggered = true;
						return; // Exit after finding the first error
					}
				}
			}
		}
	}
};

export const getIconColor = (EventTemplate) => {

	switch (EventTemplate) {

		case 1:
			return '#fdb14b';
		case 3:
			return 'black';
		default:
			return '#572148';
	}
}

export const renderIconWithColor = (EventTemplate, className = 'fa fa-caret-down d-flex align-items-center', fontSize = '22px') => {
	const color = getIconColor(EventTemplate);

	return (
		<i
			className={className}
			style={{
				color: color,
				fontSize: fontSize,
			}}
		></i>
	);
};


// Function 1: Check if event URL matches and return translation config
export const getEventTranslationConfig = (eventUrl) => {
	const eventTranslationConfig = {
		"future-sustainability-forum-2024dec52025": { translateTo: "en" },
		"paid-ea89ce0a": { translateTo: "ar" },
		"ai-future-horizons": { translateTo: "ar" },
		"arebic-language-support": { translateTo: "ar" }
	};

	if (eventTranslationConfig[eventUrl]) {
		return { match: true, translateTo: eventTranslationConfig[eventUrl].translateTo };
	}

	return { match: false };
};

const translations = {
	"About": { "en": "About", "ar": "عن" },
	"Tickets": { "en": "Ticket", "ar": "التذاكر" },
	"TICKETS": { "en": "TICKETS", "ar": "التذاكر" },
	"Venue": { "en": "Venue", "ar": "مكان" },
	"ABOUT EVENT": { "en": "ABOUT EVENT", "ar": "حول الحدث" },
	"In-person": { "en": "In-person", "ar": "شخصيا" },
	"Online": { "en": "Online", "ar": "متصل" },	
	"Free": { "en": "Free", "ar": "حر" },	
	"Paid": { "en": "Paid", "ar": "مدفوع" },	
	"Event Live Link": { "en": "Event Live Link", "ar": "رابط الحدث المباشر" },	
	"Open streaming website": { "en": "Open streaming website", "ar": "فتح موقع البث" },	
	"Hosted by": { "en": "Hosted by", "ar": "باستضافة" },	
	"Contact Us On": { "en": "Contact Us On", "ar": "اتصل بنا على" },	
	"EVENT STARTS IN": { "en": "EVENT STARTS IN", "ar": "يبدأ الحدث في" },	
	"Apply discount code to avail discounts.": { "en": "Apply discount code to avail discounts.", "ar": "تطبيق رمز الخصم للاستفادة من الخصومات." },	
	"Apply access code to view hidden tickets.": { "en": "Apply access code to view hidden tickets.", "ar": "تطبيق رمز الدخول لعرض التذاكر المخفية." },	
	"Apply discount code / access code to avail discounts & view hidden tickets": { "en": "Apply discount code / access code to avail discounts & view hidden tickets", "ar": "قم بتطبيق رمز الخصم / رمز الوصول للاستفادة من الخصومات وعرض التذاكر المخفية" },	
	"About the Event": { "en": "About", "ar": "حول الحدث" },	
	"Organized by": { "en": "Organized by", "ar": "نظمتها" },	
	"Connect with us on": { "en": "Connect with us on", "ar": "تواصل معنا على" },	
	"The Venue": { "en": "The Venue", "ar": "المكان" },	
	"About Organizer": { "en": "About Organizer", "ar": "حول المنظم" },	
	"Shout": { "en": "Shout", "ar": "يصرخ" },	
	"The": { "en": "The", "ar": "ال" },	
	"Shout About": { "en": "Shout About", "ar": "يصرخ حول" },		
	"Representatives": { "en": "Representatives", "ar": "الممثلين" },		
	"Tags": { "en": "Tags", "ar": "العلامات" },
	"Contact Us": { "en": "Contact Us", "ar": "اتصل بنا" },
	"SPEAKERS": { "en": "SPEAKERS", "ar": "المتحدثون" },

	
  };
  
  export const getTranslation = (key, lang ) => {
	return translations[key]?.[lang] || key; 
  };
  export const isMatchingUrlForHarcodeText = (inputUrl) => {
	return (
		inputUrl === "stotme2025"
	);
};
  